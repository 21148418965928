// Redux Actions for Content Types, Movies by Tags, and Movie Fetching
import axios from "axios";
import { API_URL } from "../../Utils/helpers/api_url";

// Action Types
export const FETCH_CONTENT_TYPES_REQUEST = "FETCH_CONTENT_TYPES_REQUEST";
export const FETCH_CONTENT_TYPES_SUCCESS = "FETCH_CONTENT_TYPES_SUCCESS";
export const FETCH_CONTENT_TYPES_FAILURE = "FETCH_CONTENT_TYPES_FAILURE";

export const FETCH_MOVIES_BY_TAGS_REQUEST = "FETCH_MOVIES_BY_TAGS_REQUEST";
export const FETCH_MOVIES_BY_TAGS_SUCCESS = "FETCH_MOVIES_BY_TAGS_SUCCESS";
export const FETCH_MOVIES_BY_TAGS_FAILURE = "FETCH_MOVIES_BY_TAGS_FAILURE";

export const FETCH_MORE_MOVIES_REQUEST = "FETCH_MORE_MOVIES_REQUEST";
export const FETCH_MORE_MOVIES_SUCCESS = "FETCH_MORE_MOVIES_SUCCESS";
export const FETCH_MORE_MOVIES_FAILURE = "FETCH_MORE_MOVIES_FAILURE";

// Fetch Content Types Action
export const fetchContentTypes = (page = 1, limit = 5) => {
  return async (dispatch) => {

    dispatch({ type: FETCH_CONTENT_TYPES_REQUEST });

    try {
      // console.log("Fetching Content Types");
      // Fetch content types from API
      const response = await axios.get(`${API_URL}/api/v1/content-type`);

      const contentTypes = response.data.data.contentTypes;

      // console.log("Fetched Content Types", contentTypes);

      dispatch({
        type: FETCH_CONTENT_TYPES_SUCCESS,
        payload: contentTypes,
      });

      // console.log("FETCH_CONTENT_TYPES_SUCCESS")
      // For each content type, fetch movies by tags
      contentTypes.forEach(() => {
        dispatch(fetchMoviesByTags());
      });
    } catch (error) {
      dispatch({
        type: FETCH_CONTENT_TYPES_FAILURE,
        payload: error.message,
      });
    }
  };
};

// Fetch Movies by Tags Action
// export const fetchMoviesByTags = (
//   page = 1,
//   limit = 4,
//   movieLimit = 1
// ) => {
//   return async (dispatch) => {
//     dispatch({ type: FETCH_MOVIES_BY_TAGS_REQUEST });

//     try {

//       // Fetch movies by tags from API
//       const response = await axios.get(`${API_URL}/api/v1/movies/movie-by-tags`, {
//         params: {
//           page,
//           limit,
//           movieLimit,
//         },
//       });

//       const tags = response.data.data;
//       console.log("Fetched tags", tags);

//       dispatch({
//         type: FETCH_MOVIES_BY_TAGS_SUCCESS,
//         payload: {
//           data: tags,
//         },
//       });
//       console.log("FETCH_TAGS_SUCCESS")
//     } catch (error) {
//       dispatch({
//         type: FETCH_MOVIES_BY_TAGS_FAILURE,
//         payload: error.message,
//       });
//     }
//   };
// };
// export const fetchMoviesByTags = (
//   page = 1,
//   limit = 4,
//   movieLimit = 1
// ) => {
//   return async (dispatch, getState) => {
//     // Only show loading indicator for first page or when explicitly needed
//     dispatch({
//       type: FETCH_MOVIES_BY_TAGS_REQUEST,
//       payload: { isInitialLoad: page === 1 }
//     });

//     try {
//       // Fetch movies by tags from API
//       const response = await axios.get(`${API_URL}/api/v1/movies/movie-by-tags`, {
//         params: {
//           page,
//           limit,
//           movieLimit,
//         },
//       });

//       const newTags = response.data.data;
//       console.log("Fetched tags for page", page, newTags);

//       // Get existing tags from state for appending
//       const existingTags = page > 1 ? getState().content.moviesByTags : [];

//       dispatch({
//         type: FETCH_MOVIES_BY_TAGS_SUCCESS,
//         payload: {
//           data: newTags,
//           page,
//         },
//       });
//     } catch (error) {
//       dispatch({
//         type: FETCH_MOVIES_BY_TAGS_FAILURE,
//         payload: error.message,
//       });
//     }
//   };
// };
export const fetchMoviesByTags = (
  page = 1,
  limit = 4,
  movieLimit = 1,
  contentType
) => {
  return async (dispatch, getState) => {
    dispatch({
      type: FETCH_MOVIES_BY_TAGS_REQUEST,
      payload: { page },
    });

    try {
      // console.log(`Fetching page ${page} with limit ${limit}`);

      // Fetch movies by tags from API
      const response = await axios.get(
        `${API_URL}/api/v1/movies/movie-by-tags`,
        {
          params: {
            page,
            limit,
            movieLimit,
            contentType,
          },
        }
      );

      const newTags = response.data.data;
      // console.log(`Fetched ${newTags.length} tags for page ${page}`);

      dispatch({
        type: FETCH_MOVIES_BY_TAGS_SUCCESS,
        payload: {
          data: newTags,
          page,
        },
      });
    } catch (error) {
      console.error(`Error fetching page ${page}:`, error);
      dispatch({
        type: FETCH_MOVIES_BY_TAGS_FAILURE,
        payload: {
          error: error.message,
          page,
        },
      });
    }
  };
};

// Fetch More Movies Action (for carousel pagination)
export const fetchMoreMovies = (categoryId, page = 1, limit = 5) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_MORE_MOVIES_REQUEST });

    try {
      // Fetch more movies from API
      const response = await axios.get("/api/v1/movies/fetch-movies", {
        params: {
          tags: categoryId,
          page,
          limit,
        },
      });
      console.log("Fetched movies", response);

      dispatch({
        type: FETCH_MORE_MOVIES_SUCCESS,
        payload: {
          categoryId,
          page,
          movies: response.data.movies,
        },
      });
    } catch (error) {
      dispatch({
        type: FETCH_MORE_MOVIES_FAILURE,
        payload: error.message,
      });
    }
  };
};
