import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import UniqueBtn from "../../Components/UniqueBtn";
import { MODAL_OPEN } from "../../Redux/Actions/type";

const MovieRentButton = ({ item, isAuthenticated, checkIfMovieIsInRentedList, setModalId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleWatchNow = useCallback(() => setModalId(item.id), [item.id, setModalId]);

  const handleRentNow = useCallback(() => history.push(`/payment-option/${item?.id}`), [item?.id, history]);

  const handleLoginToRent = useCallback((e) => {
    dispatch({ type: MODAL_OPEN });
    e.preventDefault();
  }, [dispatch]);

  return (
    <div className="movie-rent-container">
      {isAuthenticated ? (
        checkIfMovieIsInRentedList(item.id) ? (
          <button data-bs-toggle="modal" data-bs-target="#strModal" onClick={handleWatchNow}>
            {/* <UniqueBtn title="Watch Now" icon="" /> */}
            <button className="movie-buttons rent-btn">
            Watch Now
          </button>
          </button>
        ) : (
          <button className="movie-buttons rent-btn" onClick={handleRentNow}>
            Rent Now
          </button>
        )
      ) : (
        // <UniqueBtn title="Login to Rent" icon="" onClick={handleLoginToRent} />
        <button className="movie-buttons rent-btn" onClick={handleLoginToRent}>
            Login to Rent
          </button>
      )}
    </div>
  );
};

export default MovieRentButton;
