import React, { useState } from "react";
import classes from "./CommonMovieCard.module.css";
import AddToWishListBtn from "../AddToWhishListBtn/AddToWishListBtn";
import FullTrailerButton from "../Helper/FullTrailerButton";
import dateFormat from "dateformat";
import { useHistory } from "react-router-dom";
import yellowBagCheckIcon from "../../Assets/icons/bag-check_svgrepo.svg";
import yellowBagHeartIcon from "../../Assets/icons/bag-heart_svgrepo.svg";
import blueBagCheckIcon from "../../Assets/icons/bag-heart_svgrepo.svg";
import { useSelector } from "react-redux";

const CommonMovieCard = ({ movieItem, loggedInId, isMinHeight }) => {
  movieItem.id = movieItem.id || movieItem._id;
  const [isMouseEntered, setIsMouseEntered] = useState(false);
  const history = useHistory();
  const imgSrc = movieItem.banners[0];
  const durationList = movieItem.duration.split(":");
  const rentedList = useSelector((state) => state?.movie_list?.rentedList) || [];

  const handleMovieCardClick = () => {
    history.push(`/Movie-detail/${movieItem.id || movieItem._id}`);
  };

  const isRented = rentedList.find((item) => item.movieId === movieItem.id);
  return (
    <div
      className={`${classes.commonMovieCard} ${
        isMouseEntered && classes.moveCardUp
      } ${isMinHeight && classes.cardMinHeight}`}
      onClick={handleMovieCardClick}
      onMouseEnter={() => setIsMouseEntered(true)}
      onMouseLeave={() => setIsMouseEntered(false)}
    >
      <div className={classes.movieImageIconContainer}>
        <img
          src={imgSrc}
          className={`${classes.movieImage} ${
            !isMouseEntered && classes.borderRadiusBottom
          }`}
        ></img>
        {isRented && (
          <img src={yellowBagCheckIcon} className={classes.overLayRentIcon} />
        )}
      </div>
      {isMouseEntered && (
        <div className={classes.movieDetails}>
          <div className={classes.rentedDetails}>
            <img
              src={isRented ? blueBagCheckIcon : yellowBagHeartIcon}
              className={classes.rentIcon}
            />
            <span className="ms-1 mt-1">
              
              {isRented ? "Rented" : "Available to rent"}
            </span>
          </div>

          <div className={classes.movieTitleContainer}>
            <div className={classes.movieTitle}>{movieItem.title}</div>
            {!!loggedInId && (
              <AddToWishListBtn
                movieId={movieItem.id}
                loggedInId={loggedInId}
                key={movieItem.id}
                className="icon-btn-20 icon-blue no-border"
                iconClassName="icon-14"
              />
            )}
            <FullTrailerButton
              trailerLink={movieItem?.trailerUploadedUrl}
              className="icon-btn-20 icon-blue no-border ms-3"
              iconClassName="icon-14"
            />
          </div>

          <div className={classes.movieYearDetailContainer}>
            <span>{dateFormat(movieItem.releaseDate, "yyyy")}</span>
            <span className="ms-2">{`${durationList[0]}h ${durationList[1]} min`}</span>
          </div>

          <div className={classes.movieDescription}>
            {movieItem.description.length > 70
              ? `${movieItem.description.substring(0, 70)}...`
              : movieItem.description}
          </div>
        </div>
      )}
      {!isMouseEntered && (
        <div className={classes.movieTitleSubDescriptionSection}>
          <div className={classes.movieTitle}>{movieItem.title}</div>
          <div className={classes.movieSubTitle}>
            {movieItem.subDescription.length > 150
              ? `${movieItem.subDescription.substring(0, 150)}...`
              : movieItem.subDescription}
          </div>
        </div>
      )}
    </div>
  );
};

export default CommonMovieCard;
