import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../Utils/helpers/api_url";
import CommonMovieCard from "../../Components/CommonMovieCard/CommonMovieCard";
import "./ViewAll.css";
import { RotatingLines } from "react-loader-spinner";

const ViewAll = () => {
  const { tagName, tagId, contentTypeId } = useParams();
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const loggedInId = localStorage.getItem("id");

  useEffect(() => {
    fetchMovies();
  }, [tagId]);

  const fetchMovies = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/api/v1/movies/fetch-movies`,
        {
          params: { tags: tagId, contentType: contentTypeId},
        }
      );
      setMovies(data.data);
    } catch (error) {
      console.error("Error fetching movies:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="movies-list-page">
      <h2 className="movies-list-page-heading">{tagName}</h2>
      <p>
        <Link to="/" className="movie-list-nav">
          Home
        </Link>{" "}
        &gt; {decodeURIComponent(tagName)}
      </p>
      {loading && (
        <div
          className="loading-indicator"
          style={{ padding: "20px", textAlign: "center" }}
        >
          <RotatingLines
            height="96"
            width="96"
            color="rgba(14, 14, 13, 0.758)"
            strokeWidth="5"
            animationDuration="0.75"
          />
        </div>
      )}

      <div className="movies-grid">
        {movies
          // .filter((movie) => movie.contentType?._id === contentType._id) // Ensure only matching movies are mapped
          .map((movie) => (
            <CommonMovieCard
              key={movie._id}
              movieItem={movie}
              loggedInId={loggedInId}
            />
          ))}
      </div>
    </div>
  );
};

export default ViewAll;
