import React from "react";
import Slider from "react-slick";
import "swiper/swiper-bundle.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Helper/Style.css";
import "../Helper/Responsive.css";
export default function Crousel(props) {
  let settings = {
    infinite: props.infinite,
    initialSlide: 1,
    slidesToShow: 5,
    swipeToSlide: true,
    emulateTouch: true,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplay: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 3020, // tablet breakpoint
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },

      {
        breakpoint: 2040, // tablet breakpoint
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },

      {
        breakpoint: 1840, // tablet breakpoint
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },

      {
        breakpoint: 1260, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },

      {
        breakpoint: 760, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },

      {
        breakpoint: 248, // tablet breakpoint
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <div className="crousel-wrapper">
        <div className="container-fluid ">
          <div className="crousel-card-type">
            <div
              className="crousel-card-type-main-heading section-heading section-heading-band"
              style={{ justifyContent: "center" }}
            >
              <h3>{props.crouselHeading}</h3>
            </div>
          </div>
          <Slider {...settings}>{props.children}</Slider>
        </div>
      </div>
    </React.Fragment>
  );
}
