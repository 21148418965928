import MyPlayDetails from "./PlayDetails/MyPlayDetails";

const playDetails = () => {
  return (
    <>
      <MyPlayDetails />
    </>
  );
};

export default playDetails;