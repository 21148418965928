// Play Reducer Function file

// Import action types
import { PLAY_LIST, IS_LOADING, PLAY_BY_ID } from "../Actions/type";

// Create initialState for the reducer function
const initialState = {
  play_list: {},
  play_byId: {},
  is_loading: false,
};

// Reducer function
const playReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case IS_LOADING:
      return {
        ...state,
        is_loading: true,
        // is_loading: payload !== undefined ? payload : state.is_loading, // Ensure is_loading has a valid value
      };

    case PLAY_LIST:
      return {
        ...state,
        play_list: payload,
        is_loading: false, // Ensure loading is false after data is set
      };

    case PLAY_BY_ID:
      return {
        ...state,
        play_byId: payload,
        is_loading: false, // Ensure loading is false after data is set
      };

    default:
      return state;
  }
};

export default playReducer;
