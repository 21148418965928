import {
  FETCH_CONTENT_TYPES_REQUEST,
  FETCH_CONTENT_TYPES_SUCCESS,
  FETCH_CONTENT_TYPES_FAILURE,
  FETCH_MOVIES_BY_TAGS_REQUEST,
  FETCH_MOVIES_BY_TAGS_SUCCESS,
  FETCH_MOVIES_BY_TAGS_FAILURE,
  FETCH_MORE_MOVIES_REQUEST,
  FETCH_MORE_MOVIES_SUCCESS,
  FETCH_MORE_MOVIES_FAILURE,
} from "../Actions/content";


const initialState = {
  contentTypes: [],
  moviesByTags: [],
  loading: false,
  error: null,
  hasMore: true,
  currentPage: 1,
};


const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case FETCH_CONTENT_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CONTENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        contentTypes: [...state.contentTypes, ...action.payload],
        hasMore: action.payload.length > 0,
      };

    case FETCH_CONTENT_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // // Movies by Tags
    // case FETCH_MOVIES_BY_TAGS_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //   };

    // case FETCH_MOVIES_BY_TAGS_SUCCESS:
    //   console.log("Reducer Updating State with:", action.payload);
    //   return {
    //     ...state,
    //     loading: false,
    //     moviesByTags: action.payload.data,
    //   };

    // case FETCH_MOVIES_BY_TAGS_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     // Only clear existing data on initial load
    //     ...(action.payload.isInitialLoad && { moviesByTags: [] }),
    //   };

    // case FETCH_MOVIES_BY_TAGS_SUCCESS:
    //   console.log("Reducer Updating State with:", action.payload);
    //   return {
    //     ...state,
    //     loading: false,
    //     // Append new tags to existing ones if page > 1
    //     moviesByTags:
    //       action.payload.page > 1
    //         ? [...state.moviesByTags, ...action.payload.data]
    //         : action.payload.data,
    //   };

    // case FETCH_MOVIES_BY_TAGS_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    case FETCH_MOVIES_BY_TAGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        // Only reset data on first page load
        ...(action.payload.page === 1 && { moviesByTags: [] }),
      };

    case FETCH_MOVIES_BY_TAGS_SUCCESS:
      // console.log(
      //   `Reducer handling success for page ${action.payload.page} with ${action.payload.data.length} items`
      // );

      // If it's the first page, replace the data
      if (action.payload.page === 1) {
        return {
          ...state,
          loading: false,
          moviesByTags: action.payload.data,
          currentPage: action.payload.page,
        };
      }

      // For subsequent pages, append the new data
      return {
        ...state,
        loading: false,
        moviesByTags: [...state.moviesByTags, ...action.payload.data],
        currentPage: action.payload.page,
      };

    case FETCH_MOVIES_BY_TAGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    // Fetch More Movies (carousel pagination)
    case FETCH_MORE_MOVIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_MORE_MOVIES_SUCCESS: {
      const { categoryId, page, movies } = action.payload;

      // Create a deep copy of moviesByTags
      const updatedMoviesByTags = { ...state.moviesByTags };

      // Find all content types that have this category
      Object.keys(updatedMoviesByTags).forEach((contentTypeId) => {
        // Check if this content type has the category we're updating
        const tagGroups = updatedMoviesByTags[contentTypeId];

        if (tagGroups) {
          // Find the tag group with matching categoryId
          const updatedTagGroups = tagGroups.map((tagGroup) => {
            if (tagGroup.categoryId === categoryId) {
              // Update the movies and current page
              return {
                ...tagGroup,
                movies: page === 1 ? movies : [...tagGroup.movies, ...movies],
                currentPage: page,
              };
            }
            return tagGroup;
          });

          updatedMoviesByTags[contentTypeId] = updatedTagGroups;
        }
      });

      return {
        ...state,
        loading: false,
        moviesByTags: updatedMoviesByTags,
      };
    }

    case FETCH_MORE_MOVIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default contentReducer;
