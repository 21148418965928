import React, { useEffect, useRef } from "react";
import shaka from "shaka-player";

const CustomVideoPlayer = ({ mpdUrl }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    // Initialize Shaka Player
    const player = new shaka.Player(video);

    // Error handling
    player.addEventListener("error", (event) => {
      console.error("Error with Shaka Player:", event.detail);
    });

    // Load the MPD file
    if (mpdUrl) {
      player
        .load(mpdUrl)
        .then(() => {
          console.log("The video has been successfully loaded!");
        })
        .catch((error) => {
          console.error("Error loading video:", error);
        });
    }

    // Cleanup
    return () => {
      player.destroy();
    };
  }, [mpdUrl]);

  return (
    <div style={{ position: "relative" }}>
      <video
        ref={videoRef}
        controls
        autoPlay
        width="100%"
        style={{
          maxHeight: "800px",
          border: "1px solid #ccc",
          background: "black",
        }}
      />
    </div>
  );
};

export default CustomVideoPlayer;
