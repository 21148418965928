import { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import ContentCarousel from "../ContentCarousel/ContentCarousel";
import { API_URL } from "../../Utils/helpers/api_url";
import "./ContentList.css"

const ContentList = ({ contentType }) => {
  // console.log("content Type rendering", contentType)
  const [moviesByTags, setMoviesByTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef();
  const loadedPages = useRef(new Set());

  const fetchMoviesByTags = useCallback(
    async (pageNum, limit = 4, movieLimit = 1) => {
      if (loadedPages.current.has(pageNum)) return;
      setLoading(true);

      try {
        const response = await axios.get(
          `${API_URL}/api/v1/movies/movie-by-tags`,
          {
            params: { page: pageNum, limit, movieLimit, contentType: contentType._id },
          }
        );

        // console.log("Fetch by tags", response.data.data)
        const newTags = response.data.data;
        // console.log("New tags", newTags)
        setMoviesByTags((prev) =>
          pageNum === 1 ? newTags : [...prev, ...newTags]
        );

        if (newTags.length < limit) setHasMore(false);
        loadedPages.current.add(pageNum);
      } catch (err) {
        setError(err.message || "Failed to load movies");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    fetchMoviesByTags(1);
  }, [fetchMoviesByTags]);

  useEffect(() => {
    if (page > 1) fetchMoviesByTags(page);
  }, [page, fetchMoviesByTags]);

  const lastTagElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) setPage((prev) => prev + 1);
        },
        { threshold: 0.1 }
      );

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  if (error && moviesByTags.length === 0)
    return <div className="error-message">Error: {error}</div>;

  // console.log("Movies by Tags", moviesByTags)
  // Filter out tags where moviesList is empty or ContentCarousel would not render
  const filteredTags = moviesByTags.filter((tag) => {
    const hasValidMovies = tag.movies?.some(
      (movie) => movie.contentType?._id === contentType._id
    );
    return hasValidMovies;
  });

  // console.log("Filtered Tags", filteredTags)
  // If no valid tags exist, do not render ContentList
  if (moviesByTags.length === 0) return null;

  return (
    <div
      className="content-type-heading-container section-heading-wrapper"
    >
     
      <h2 className="content-type-heading">{contentType.displayName}</h2>

      {moviesByTags.map((tag, index) => (
        <div
          key={tag._id || `tag-${index}`}
          ref={index === filteredTags.length - 1 ? lastTagElementRef : null}
        >
          <ContentCarousel
            tag={tag}
            moviesList={tag.movies}
            contentType={contentType}
          />
        </div>
      ))}

      {loading && (
        <div
          className="loading-indicator"
          style={{ padding: "20px", textAlign: "center" }}
        >
          <RotatingLines
            height="96"
            width="96"
            color="rgba(14, 14, 13, 0.758)"
            strokeWidth="5"
            animationDuration="0.75"
          />
        </div>
      )}
    </div>
  );
};

export default ContentList;
