import { useEffect, useState, useRef } from "react";
import CommonMovieCard from "../CommonMovieCard/CommonMovieCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import { API_URL } from "../../Utils/helpers/api_url";
import "./ContentCarousel.css";

import { Link } from "react-router-dom";

const responsive = {
  superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 3 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 2 },
};

const ContentCarousel = ({ tag, moviesList = [], contentType }) => {
  const [movies, setMovies] = useState(moviesList);
  const [loading, setLoading] = useState(false);
  const [hasFetchedMore, setHasFetchedMore] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const carouselRef = useRef(null);
  const loggedInId = localStorage.getItem("id");

  useEffect(() => {
    if (!hasFetchedMore) fetchMoreMovies();
  }, [JSON.stringify(tag)]);

  const fetchMoreMovies = async () => {
    if (loading || hasFetchedMore) return;
    setLoading(true);

    try {
      const { data } = await axios.get(
        `${API_URL}/api/v1/movies/fetch-movies`,
        {
          params: { tags: tag.tagId, contentType: contentType._id },
        }
      );

      // console.log("fetch more movies", data, contentType)
      if (data?.data?.length > 0) {
        setMovies((prevMovies) => {
          // Only update if new movies are fetched
          const movieIds = new Set(prevMovies.map((m) => m._id));
          const newMovies = data.data.filter((m) => !movieIds.has(m._id));
          return newMovies.length ? [...prevMovies, ...newMovies] : prevMovies;
        });

        setHasFetchedMore(true);
        setTimeout(() => carouselRef.current?.goToSlide(currentSlideIndex), 0);
      }
    } catch (error) {
      console.error("Error fetching more movies:", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredMovies = movies.filter((movie) => movie.contentType?._id === contentType._id);

  // If there are no movies, do not render anything
  if (movies.length === 0) return null;

  return (
    <div className="movie-carousel-container">
      <div className="carousel-heading-wrapper">
        <h3 className="tag-name-heading">{tag.tag}</h3>
        <Link
          to={`/view-all/${encodeURIComponent(
            contentType.displayName
          )}/${encodeURIComponent(tag.tag)}/${contentType._id}/${tag.tagId}`}
          className="view-all-link"
        >
          View All
        </Link>
      </div>

      {movies.length > 0 && (
        <Carousel
          infinite
          responsive={responsive}
          sliderClass="slider-style"
          containerClass="carousel-container"
          afterChange={(previousSlide, { currentSlide }) => {
            setCurrentSlideIndex(currentSlide);
            if (!hasFetchedMore && currentSlide === movies.length - 1) {
              fetchMoreMovies();
            }
          }}
          ref={carouselRef}
        >
          {/* {console.log("Movies List in Carousel:", movies)}
          {console.log("Content Type in Carousel:", contentType)} */}
          {movies
            .map((movie) => (
              <CommonMovieCard
                key={movie._id}
                movieItem={movie}
                loggedInId={loggedInId}
              />
            ))}
        </Carousel>
      )}
    </div>
  );
};

export default ContentCarousel;
