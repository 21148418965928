import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  topTrandingMovies,
  comingMovies,
  recentMovie,
  updatedRentedList,
} from "../../Redux/Actions/movies";
import {
  fetchContentTypes,
  fetchMoviesByTags,
} from "../../Redux/Actions/content";
import CustomSlider from "../../Components/Helper/CustomSlider";
import Crousel from "../../Components/Helper/Crousel";
import Register from "../../Components/Helper/Register";
import CommonMovieCard from "../../Components/CommonMovieCard/CommonMovieCard";
import ReferNow from "../../Components/Helper/ReferNow";
import ReferPopup from "../../Components/Helper/Modal/ReferPopup";
import DownloadScreen from "../../Components/Helper/downloadScreen";
import WhyDhakad from "../../Components/Helper/whyDhakad";
import Avengers from "../../Components/Helper/avengers";
import ContentList from "../../Components/ContentList/ContentList";
import "./index.css";

const Home = () => {
  // console.count("Home Component Rendered"); // Debugging re-renders
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);
  const loggedInId = localStorage.getItem("id");

  const {
    contentTypes: contentTypesData,
    moviesByTags: moviesByTagsData,
    hasMore,
    loading,
  } = useSelector((state) => state.content);
  // console.log("Content type", contentTypesData)

  const comingMoviesData = useSelector(
    (state) => state.movie_list?.coming_movie?.movies || []
  );

  const referralCode = useSelector(
    (state) => state?.user?.user?.data?.user?.referralCode
  );

  useEffect(() => {
    dispatch(topTrandingMovies());
    dispatch(comingMovies());
    dispatch(recentMovie());
    dispatch(updatedRentedList());
    if (!contentTypesData.length) {
      dispatch(fetchContentTypes());
    }
    dispatch(fetchMoviesByTags());
  }, [dispatch, contentTypesData.length]);

  const memoizedContentTypes = useMemo(
    () => contentTypesData,
    [contentTypesData]
  );
  // console.log("memoized", memoizedContentTypes)

  const openReferModal = useCallback(() => {
    setPopup(true);
    document.body.classList.add("disable-scroll");
  }, []);

  const handleClose = useCallback(() => {
    setPopup(false);
    document.body.classList.remove("disable-scroll");
  }, []);

  return (
    <>
      <CustomSlider />
      {/* <RecentMoviesCrousel /> */}
      {/* <RecentPlaysCrousel title="Play" /> */}
      {comingMoviesData.length > 0 && (
        <Crousel
          infinite={comingMoviesData.length >= 5}
          crouselHeading="| Coming Soon"
        >
          {comingMoviesData.map((item) => (
            <CommonMovieCard
              key={item.id}
              movieItem={item}
              loggedInId={loggedInId}
            />
          ))}
        </Crousel>
      )}


      {/* {console.log("Content Types fetched", memoizedContentTypes)} */}
      {memoizedContentTypes.map((item) => (
        <ContentList key={item._id} contentType={item} />
      ))}

      
      <Register />
      {popup && (
        <ReferPopup handleClose={handleClose} referralCode={referralCode} />
      )}
      <ReferNow openReferModal={openReferModal} />
      <WhyDhakad />
      <Avengers />
      <DownloadScreen />
    </>
  );
};

export default Home;
