import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import BottomNavbar from "../../Components/Helper/BottomNavbar";
import { useDispatch, connect } from "react-redux";
import { getRentedMovie, purchaseHistory } from "../../Redux/Actions/movies";
import LoadingSpinner from "../../Components/LoaderSpinner";
import demo_img from "../../Assets/Images/demo_img.png";
import dateFormat from "dateformat";
import { Button } from "react-bootstrap";
import { API_URL } from "../../Utils/helpers/api_url";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";

const PurchaseHistory = ({ Is_loading, purch_list, rented_movieList }) => {
  const id = localStorage.getItem("id");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(purchaseHistory(id));
    // dispatch(getRentedMovie(id));
  }, [dispatch, id]);

  const data_list = purch_list?.results;
  /*const rented_data = rented_movieList?.data?.order?.filter(
        (item) => item.userId._id === id
    ); */
  // loading
  const loggedIn = () => {
    if (Is_loading === true) {
      return <LoadingSpinner />;
    }
  };
  // const generateInvoice = (movie) => {

  //   const invoiceText = `Invoice for ${movie?.movieId?.title}\nPrice: ₹${movie?.movieId?.price}`;
  //   alert(invoiceText);
  // };
  const generateInvoice = async (movieTransId) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/v1/trasncations/getHTMLString/${movieTransId}`
      );
      const htmlString = response.data.data;
      const iframe = document.createElement("iframe");
      iframe.style.visibility = "hidden";
      document.body.appendChild(iframe);
      const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
      iframedoc.body.innerHTML = htmlString;

      const canvas = await html2canvas(iframedoc.body, { useCORS: true });

      // Convert the iframe into a PNG image using canvas.
      const imgData = canvas.toDataURL("image/png");

      // Create a PDF document and add the image as a page.
      const doc = new jsPDF({
        format: "a4",
        unit: "mm",
      });
      doc.addImage(imgData, "PNG", 0, 0);

      // Save the PDF or display it as needed
      doc.save(`dhaakad_cinema_invoice_${movieTransId}.pdf`);

      // Remove the iframe from the document when the file is generated.
      document.body.removeChild(iframe);
    } catch (e) {
      console.error("Error generating PDF:", e);
    }
  };
  console.log(`PurchaseHistory:PurchaseHistory:x - `, data_list);
  return (
    <React.Fragment>
      <div className="main-content">
        <BottomNavbar />
        <div className="container-fluid padding-globle">
          <div className="history-header ">
            <div className="booking-all-history">
              <div className="container">
                <div className="col-12 col-sm-12 col-md-10  mx-auto">
                  {data_list && data_list?.length > 0 ? (
                    data_list?.map((item) => {
                      return (
                        <div className="card my-4" key={item.id}>
                          <div className="row m-0">
                            <div className="card-poster col-12 col-sm-4 col-md-3 col-lg-2 p-0">
                              <img
                                src={
                                  item?.movieId?.banners
                                    ? item?.movieId?.banners?.[0]
                                    : demo_img
                                }
                                alt="banners"
                              />
                            </div>
                            <div className="col-12 col-sm-8 col-md-9 col-lg-10 py-2">
                              <div className="d-flex justify-content-between">
                                <h5 className="m-0">{item?.movieId?.title}</h5>
                                {item.status &&
                                item.status === "PAYMENT_SUCCESS" ? (
                                  <span>
                                    {" "}
                                    Purchase at :{" "}
                                    {dateFormat(item?.createdAt, "dd/mm/yyyy")}
                                  </span>
                                ) : (
                                  <span>
                                    Status:{" "}
                                    {item?.status &&
                                    item.status === "PAYMENT_INITIATED"
                                      ? "Payment not complete"
                                      : "transaction failed"}
                                  </span>
                                )}
                              </div>
                              <h6 className="my-2">
                                Price : ₹ {item?.movieId?.price}
                              </h6>
                              <div className="d-flex justify-content-between">
                                {item.streamed ? (
                                  <span>
                                    <em>
                                      Stream started on :{" "}
                                      {dateFormat(
                                        item?.startedAt,
                                        "dd/mm/yyyy HH:mm"
                                      )}
                                    </em>
                                  </span>
                                ) : (
                                  <span>
                                    <em>Not watched yet</em>
                                  </span>
                                )}

                                <span
                                  style={{
                                    textAlign: "right",
                                    marginleft: "100px",
                                  }}
                                >
                                  <Button
                                    className="btn"
                                    style={{
                                      background:
                                        "linear-gradient(90deg, #FFCC00 0%, #FFB800 100%)",
                                      color: "#5A0223",
                                      border: "none",
                                    }}
                                    onClick={() => generateInvoice(item.id)}
                                  >
                                    Download Invoice
                                  </Button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <h3 className="text-center mb-4">
                      You don't seem to have any recent bookings.
                    </h3>
                  )}
                  {loggedIn()}
                  {/*data_list && data_list?.length > 1 ? (
                                        <Link to="/purchased-all-history">View all bookings</Link>
                                    ) : (
                                        ""
                                    )*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  Is_loading: state?.movie_list?.is_loading,
  purch_list: state?.movie_list?.purchased_list,
  rented_movieList: state?.movie_list?.rented_mov,
});
export default connect(mapStateToProps, { purchaseHistory })(PurchaseHistory);
