import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "../Helper/Style.css";
import SliderMain from "../SliderMain";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import RatingThumb1 from "../../Assets/Images/sparkler.gif";
import RatingThumb2 from "../../Assets/Images/rating-thumb-2.gif";
import RatingThumb3 from "../../Assets/Images/bomb.gif";
import RatingThumb4 from "../../Assets/Images/nuclear 2.gif";
import RatingThumb5 from "../../Assets/Images/volcano.gif";
import RatingThumb6 from "../../Assets/Images/anar 2.gif";
import dhakad from "../../Assets/Images/dhakdImage.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { featuredMovies, updatedRentedList } from "../../Redux/Actions/movies";
import DhakadRating from "../DhakadRating";
import ShareIcon from "@material-ui/icons/Share";
import DoneIcon from "@material-ui/icons/Done";
import Share from "../Helper/Modal/Share";
import UniqueBtn from "../../Components/UniqueBtn";
import StarIcon from "@material-ui/icons/Star";
import dateFormat from "dateformat";
import checkAuthenticate from "../../Utils/helpers/IsAuthenticate";
import { useHistory } from "react-router-dom";
import StrModal from "./Modal/StreamModal";
import FullTrailerButton from "./FullTrailerButton";
import FullScreenTrailer from "./Modal/FullScreenTrailer";
import AddToWishListBtn from "../AddToWhishListBtn/AddToWishListBtn";
import Tooltip from "@material-ui/core/Tooltip";
import { MODAL_OPEN, MODAL_CLOSE } from "../../Redux/Actions/type";
import "./custom-slider.css";
import MovieRentButton from "./MovieRentButton";

SwiperCore.use([Navigation, Pagination, Autoplay]);
const token = localStorage.getItem("token");
const isAuthenticated = token;
const loggedInId = localStorage.getItem("id");

const CustomSlider = (props) => {
  const [movieId, setMovieId] = useState();
  const [transId, setTransId] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(featuredMovies());
    dispatch(updatedRentedList());
  }, [dispatch]);

  const movieListStore = useSelector((state) => state.movie_list);

  let newObj = movieListStore?.featured_lists?.data?.movies
    ? movieListStore.featured_lists.data.movies
    : [];

  // let testObj = { test: "test" };

  const featuredMovie = [...newObj];
  // console.log("featuredMovie", featuredMovie);

  const rentedList = movieListStore?.rentedList;

  // loading
  const loggedIn = () => {
    if (props.is_loading === true) {
      // return <LoadingSpinner />;
    }
  };
  const getRatingStatus = (rattingStatus) => {
    switch (rattingStatus) {
      case "Rocket":
        return RatingThumb2;
      case "Sparkle":
        return RatingThumb1;
      case "Bomb":
        return RatingThumb3;
      case "Nuclear":
        return RatingThumb4;
      case "Volcano":
        return RatingThumb5;
      case "anar":
        return RatingThumb6;
      default:
        break;
    }
  };

  const onSlideMainClick = (movieDetails) => {
    // console.log(`Slider:onSlideMainClick:x - `, movieDetails);
    // history.push('/Movie-detail/'+movieDetails.id);
  };

  const onNavigateToMovieDetails = (movieDetails) => {
    history.push("/movie-detail/" + movieDetails.id);
  };

  const checkIfMovieIsInRentedList = (movieId) => {
    return rentedList.find((item) => item.movieId === movieId);
  };

  const setModalId = (movieId) => {
    const rentedItem = rentedList.find((item) => item.movieId === movieId);
    setMovieId(movieId);
    setTransId(rentedItem.transId);
  };

  return (
    <React.Fragment>
      {loggedIn()}
      <div
        className="main-swiper-slider home-slider"
        id="masthead-overlay-swiper"
      >
        <Swiper
          autoplay={{
            disableOnInteraction: false, // Optional, but recommended
            delay: 30000,
            pauseOnMouseEnter: true,
          }}
          initialSlide={1}
          navigation={true}
          speed={1000}
          spaceBetween={20}
          slidesPerView={1}
          loop={true}
          centeredSlides={true}
          pagination={{
            clickable: true,
            el: `.swiper-pagination`,
          }}
          className="mySwiper"
        >
          {featuredMovie
            ? featuredMovie?.map((item, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    onClick={() => {
                      onSlideMainClick(item);
                    }}
                  >
                    <SliderMain
                      title="Premiere"
                      SliderBanner={item.test ? dhakad : item.banners?.[0]}
                      changeReatingIcon={RatingThumb3}
                      hoverPoster={item.banners?.[0]}
                      videoSrc={item?.trailerUrl?.[0]}
                    >
                      {item?.title && (
                        <div className="movie-detail  col-md-12">
                          <div className="movie-title">
                            <h1>{item?.title}</h1>
                          </div>

                          {/* <div className="movie-time">
                            {item?.brandTitle ? item?.brandTitle : ""} <br />
                            {item?.duration} •{" "}
                            {item?.categories
                              ?.map((category) => category.title)
                              .join(",")}{" "}
                            • {item?.ageGroup?.join(",")}
                          </div> */}

                          <div className="movie-EventAttributes">
                            <ul>
                              <li>{dateFormat(item?.releaseDate, "yyyy")}</li>
                              <li>
                                {item.duration}
                                {/* {console.log(
                                  "Custom Slider movie detail duration",
                                  item
                                )} */}
                              </li>

                              <li className="list-item-card">
                                {item.ageGroup}
                              </li>
                              {/* <li
                                className="border-rounded"
                                style={{
                                  width: 62,
                                  borderRadius: 50,
                                  height: 32,
                                  color: " #ffb800",
                                  border: "1px solid #ffb800",
                                  padding: "3px 0px",
                                  fontWeight: 300,
                                  textAlign: "center",
                                }}
                              >
                                18+
                              </li>
                              <li className="font-icon">
                                <StarIcon />
                              </li> */}
                              <li>{item?.averageRating?.toPrecision(2)}</li>

                              {/* <li>
                                <h7>
                                  {item?.languages
                                    ?.map((language) => language.title)
                                    .join(",")}
                                </h7>
                                
                              </li> */}
                            </ul>
                          </div>

                          <DhakadRating
                            rating={item?.dhaakadRating}
                            reatingIcon={getRatingStatus(item?.dhaakadRating)}
                            tooltipText={
                              item?.toolTip
                                ? item?.toolTip
                                : "Lorem Ipsum has been the industry's standard"
                            }
                          />
                          <div className="rent-text-container">
                            <p className="rent-text">
                              <span>
                                <DoneIcon className="movie-slider-done-icon" />
                                Watch at just INR
                              </span>
                              <span className="text-decoration-line-through">
                                {item?.price}
                              </span>
                              <span>{item?.offerPrice} (No Ads)</span>
                            </p>
                          </div>

                          <div className="movie-actions-container">
                            {/* {above classes removd movie-information-outer} */}
                            {/* <div className="movie-icon-button rent-option-button">
                              {isAuthenticated ? (
                                checkIfMovieIsInRentedList(item.id) ? (
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#strModal"
                                    onClick={() => {
                                      setModalId(item.id);
                                    }}
                                  >
                                    <UniqueBtn title={`Watch Now`} icon="" />
                                  </a>
                                ) : (
                                  // <UniqueBtn
                                  //   title={`Rent Now`}
                                  // onClick={() => {
                                  //   history.push(
                                  //     `/payment-option/${item?.id}`
                                  //   );
                                  // }}
                                  //   icon=""
                                  // />
                                  <button
                                    className="rent-container"
                                    onClick={() => {
                                      history.push(
                                        `/payment-option/${item?.id}`
                                      );
                                    }}
                                  >
                                    Rent Now
                                  </button>
                                )
                              ) : (
                                <UniqueBtn
                                  title={`Login to Rent`}
                                  icon=""
                                  onClick={(e) => {
                                    dispatch({
                                      type: MODAL_OPEN,
                                    });
                                    e.preventDefault();
                                  }}
                                />
                              )}
                            </div> */}

                            <MovieRentButton
                              item={item}
                              isAuthenticated={isAuthenticated}
                              checkIfMovieIsInRentedList={
                                checkIfMovieIsInRentedList
                              }
                              setModalId={() => setModalId(item.id)}
                              history={history}
                              dispatch={dispatch}
                            />

                            {/* <div className="movie-information">
                              <UniqueBtn
                                title="More Info"
                                icon=""
                                onClick={() => onNavigateToMovieDetails(item)}
                              />                              
                            </div> */}

                            <div>
                              <button
                                className="movie-buttons more-info-btn"
                                onClick={() => onNavigateToMovieDetails(item)}
                              >
                                More Info
                              </button>
                            </div>

                            {isAuthenticated && (
                              <div className="ml-10px">
                                <AddToWishListBtn
                                  movieId={item.id}
                                  loggedInId={loggedInId}
                                  key={item.id}
                                  className="icon-btn-40 icon-white"
                                  iconClassName="icon-20"
                                />
                              </div>
                            )}

                            <div
                              className="ml-10px"
                              data-bs-toggle="modal"
                              data-bs-target="#shareModal"
                            >
                              <Share
                                link={`/movie-detail/${item?._id}?shareT=true`}
                                modalBtn={
                                  <Tooltip title="share">
                                    <button className="icon-btn icon-btn-40 icon-yellow">
                                      <ShareIcon className="icon-20" />
                                    </button>
                                  </Tooltip>
                                }
                              />
                            </div>

                            <div>
                              <FullTrailerButton
                                className="icon-btn-40 icon-yellow ml-10px"
                                trailerLink={item.trailerUploadedUrl}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </SliderMain>
                  </SwiperSlide>
                );
              })
            : ""}
          <div className="swiper-pagination"></div>
        </Swiper>
        <StrModal modalId="strModal" movieId={movieId} transId={transId} />
        <FullScreenTrailer
          modalId="fullScreenModalTrailerId"
          trailerUrl="https://storage.googleapis.com/dhaakadmovies/output/6789fd390b37b3e6dc2bc29f/trailer/manifest.mpd"
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  is_loading: state?.movie_list?.is_loading,
});

export default connect(mapStateToProps, {
  MODAL_OPEN,
  MODAL_CLOSE,
  featuredMovies,
  updatedRentedList,
})(CustomSlider);
