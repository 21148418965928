import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import UniqueBtn from "../../Components/UniqueBtn";
import Crousel from "./Crousel";
import Register from "./Register";
import MovieDetailSlider from "./MovieDetailSlider";
import RatingProfile from "../../Assets/Images/10.svg.png";
import Rating from "../Rating";
import { FaEdit } from "@react-icons/all-files/fa/FaEdit";
import RatingReview from "../RatingReviews";
import { SwiperSlide } from "swiper/react";
import MovieDetailBanner from "./MovieDetailBanner";
import RatingThumb1 from "../../Assets/Images/sparkler.gif";
import RatingThumb2 from "../../Assets/Images/rating-thumb-2.gif";
import RatingThumb3 from "../../Assets/Images/bomb.gif";
import RatingThumb4 from "../../Assets/Images/nuclear 2.gif";
import RatingThumb5 from "../../Assets/Images/volcano.gif";
import RatingThumb6 from "../../Assets/Images/anar 2.gif";

import { NavLink } from "react-router-dom";
import Rent from "../Helper/Modal/Rent";
import DhakadRating from "../DhakadRating";
import ShareIcon from "@material-ui/icons/Share";
import Share from "./Modal/Share";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  getMovie,
  movieLists,
  moreLikeMovie,
  updatedRentedList,
} from "../../Redux/Actions/movies";
import LoadingSpinner from "../LoaderSpinner";
import { getUser } from "../../Redux/Actions/auth";
import { API_URL } from "../../Utils/helpers/api_url";
import { Formik } from "formik";
import { addReview } from "../../Redux/Actions/review";
import checkAuthenticate from "../../Utils/helpers/IsAuthenticate";
import { reviewListByMovies } from "../../Redux/Actions/review";
import dateFormat from "dateformat";
import Trailer from "./Modal/Trailer";
import { Modal } from "bootstrap";
import axios from "axios";
import StrModal from "./Modal/StreamModal";
import FullTrailerButton from "./FullTrailerButton";
import CommonMovieCard from "../CommonMovieCard/CommonMovieCard";

const loggedInId = localStorage.getItem("id");
const MyMovie = (props) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const isViaShare = queryParams.get("shareT");

  const user_id = loggedInId;
  const token = localStorage.getItem("token");
  const isAuthenticated = token;

  const dispatch = useDispatch();
  const { id } = useParams();
  // set star ratting
  const [start, setStars] = useState();
  const [isTrailerPlayed, setIsTrailerPlayed] = useState(false);
  const [movieTransactionId, setMovieTransactionId] = useState();
  //movie details
  const [movieDetail, setMovieDetail] = useState();
  useEffect(() => {
    axios
      .get(`${API_URL}/api/v1/movies/${id}`)
      .then((result) => setMovieDetail(result.data))
      .catch((error) => {
        console.error("MyMovie:: ", error);
      });
    if (loggedInId) {
      axios
        .get(`${API_URL}/api/v1/trasncations/check/${id}`)
        .then((result) => {
          setMovieTransactionId(result.data.movieTransactionId);
        })
        .catch((error) => {
          console.error("MyMovie:error: ", error);
        });
    }
  }, [id]);

  const movieDetails = movieDetail?.data?.movie ? movieDetail?.data?.movie : "";
  const castIds = movieDetails?.castIds?.toString()?.replace(/ /g, "");
  const [castDetailIds, setCastDetailIds] = useState();
  useEffect(() => {
    {
      movieDetails &&
        axios
          .get(`${API_URL}/api/v1/casts?castIds=${castIds}`)
          .then((result) => setCastDetailIds(result.data))
          .catch((error) => {
            console.error("MyMovie: ", error);
          });
    }
  }, [castIds]);
  const castsIds = castDetailIds?.data?.cast ? castDetailIds?.data?.cast : "";
  // like more movie
  const movieListStore = useSelector(
    (state) => state?.movie_list?.relatedmovie_lists
  );

  const categoriesType = movieDetails?.categories?.join(",");

  useEffect(() => {
    dispatch(moreLikeMovie(categoriesType));
    dispatch(updatedRentedList());
  }, [categoriesType, dispatch]);

  const moreMovieDetails = movieListStore?.data?.movies
    ? movieListStore?.data?.movies
    : "";

  //get user

  let userDetails;
  const userData = useSelector((state) => state?.user?.user);
  useEffect(() => {
    if (loggedInId) {
      dispatch(getUser());
    }
  }, [dispatch]);
  if (loggedInId) {
    userDetails = userData ? userData?.data?.user : "";
  }

  const loggedIn = () => {
    if (props.is_loading === true) {
      return <LoadingSpinner />;
    }
  };
  // Rating status
  const getRatingStatus = (rattingStatus) => {
    switch (rattingStatus) {
      case "Rocket":
        return RatingThumb2;
      case "Sparkle":
        return RatingThumb1;
      case "Bomb":
        return RatingThumb3;
      case "Nuclear":
        return RatingThumb4;
      case "Volcano":
        return RatingThumb5;
      case "anar":
        return RatingThumb6;
      default:
        break;
    }
  };

  // scroll page
  let myRef = useRef();
  // review
  const reviewData = useSelector((state) => state.reviews);
  useEffect(() => {
    dispatch(reviewListByMovies({ id }));
  }, [dispatch, id]);

  const reviewLists = reviewData?.review_list?.data?.data?.filter(
    (item) => item?.isApproved === true
  );
  const chechReview = reviewLists?.filter(
    (item) => item?.user?._id === user_id
  );

  const trailerModalElement = document.getElementById("trailerModal");
  let modalRef;
  let videoJsPlayer;

  const handlePlayerReady = (player) => {
    videoJsPlayer = player;
    setTimeout(() => {
      videoJsPlayer.play();
    }, 500);
  };
  if (
    isViaShare &&
    movieDetails &&
    movieDetails.trailerUrl?.length > 0 &&
    trailerModalElement &&
    !isTrailerPlayed
  ) {
    modalRef = new Modal(trailerModalElement, { backdrop: false });
    modalRef.show();
  }

  const onHandleTrailerClose = () => {
    modalRef.hide();
    setIsTrailerPlayed(true);
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 500);
  };

  const shareSection = (
    <div
      className="share-movie-btn"
      data-bs-toggle="modal"
      data-bs-target="#shareModal"
    >
      <Share
        link={`/movie-detail/${movieDetails ? movieDetails?._id : ""}`}
        modalBtn={
          <UniqueBtn
            className=""
            title="Share"
            icon={<ShareIcon />}
          ></UniqueBtn>
        }
      />
    </div>
  );

  return (
    <React.Fragment>
      {!movieDetails ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="slider-outer">
            <MovieDetailSlider>
              {movieDetails
                ? movieDetails?.banners?.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <MovieDetailBanner BannerSrc={item}></MovieDetailBanner>
                      </SwiperSlide>
                    );
                  })
                : ""}
            </MovieDetailSlider>

            <div className="slider-overlay" id="sliderpoints-wrapper">
              <div className="container container-banner" id="top">
                <div className="row">
                  {/*<div className="col-sm-5 slider-cards">
                                    <div className="movie-card-wrapper">
                                        <div className="movie-card-my-movie">
                                            <img src={movieDetails?.banners?.[0]} className="w-100 h-100"/>
                                            <HoverPlayer
                                                videoUrl={movieDetails?.trailerUrl?.[0]}
                                                hoverPoster={movieDetails?.banners?.[0]}
                                            />
                                        </div>
                                    </div>
                                </div>*/}
                  <div className="col-sm-12 sliderpoints-bannercard">
                    <div className="movie-detail-wrapper">
                      <div className="movie-detail">
                        <div className="movie-iconbtn">
                          <div className="movie-icon-button">
                            {isAuthenticated ? (
                              <Rent
                                modalBtn={
                                  <NavLink
                                    to={`/payment-option/${movieDetails?.id}`}
                                  ></NavLink>
                                }
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          {/*<p>Expiry in one week</p>*/}
                        </div>
                        <h1 className="movie-title">{movieDetails?.title}</h1>
                        <span className="movie-time">
                          {`${movieDetails?.duration} • ${
                            movieDetails?.categories
                              ? movieDetails?.categories?.map((category) => category.title).join(",")
                              : ""
                          } • ${movieDetails?.ageGroup?.join(",")}`}
                        </span>
                        <div className="movie-EventAttributes">
                          <h6 to="/">
                            {/* {movieDetails?.languages?.slice(0, 3)?.join(",")}  */}
                            {movieDetails?.languages?.map((language) => language.title).join(",")}
                          </h6>
                        </div>
                        <DhakadRating
                          reatingIcon={getRatingStatus(
                            movieDetails?.dhaakadRating
                          )}
                          tooltipText={
                            movieDetails?.toolTip
                              ? movieDetails?.toolTip
                              : "Lorem Ipsum has been the industry's standard"
                          }
                        />
                        <p>{movieDetails?.description}</p>
                        <div className="">
                          <div className="flex">
                            <div className=" movie-icon-button ">
                              {isAuthenticated ? (
                                movieTransactionId ? (
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#strModal"
                                  >
                                    <UniqueBtn title={`Play Now`} icon="" />
                                  </a>
                                ) : (
                                  <Rent
                                    modalBtn={
                                      <NavLink
                                        to={`/payment-option/${movieDetails?.id}`}
                                      >
                                        <UniqueBtn
                                          title={`RENT ₹ ${movieDetails?.price}`}
                                          icon=""
                                        />
                                      </NavLink>
                                    }
                                  />
                                )
                              ) : (
                                <UniqueBtn
                                  title={`RENT ₹ ${movieDetails?.price}`}
                                  icon=""
                                  onClick={() => checkAuthenticate()}
                                />
                              )}
                            </div>
                            {shareSection}
                            <FullTrailerButton
                              className="icon-btn-40 icon-yellow margleft "
                              trailerLink={movieDetails?.trailerUrl?.[0]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container-fluid padding-globle"
            id="movie-band-details"
          >
            <div className="movie-about section-heading section-heading-band">
              <h3>About the movie</h3>
              <p>{movieDetails?.description}</p>
              <hr style={{ width: "30%", border: "0.2px solid #FFFFFF" }} />
            </div>
            <div className="movie-cast-detail section-heading section-heading-band">
              <h3>About the cast</h3>
              <div className="movie-subtitle row">
                {castsIds
                  ? castsIds?.map((cast, index) => {
                      return (
                        <div className="movie-subcast" key={index}>
                          <img
                            src={cast?.photo}
                            alt="cast"
                            className="rounded-circle"
                          />
                          <h5 className="card-title">{`${cast?.firstName} ${cast?.lastName}`}</h5>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>

          {moreMovieDetails.length > 0 && (
            <Crousel
              infinite={moreMovieDetails.length >= 5 ? true : false}
              crouselHeading="More Like this"
            >
              {moreMovieDetails
                ? moreMovieDetails?.map((item, index) => {
                    return (
                      <CommonMovieCard
                        movieItem={item}
                        key={item.id}
                        loggedInId={loggedInId}
                      />
                    );
                  })
                : ""}
            </Crousel>
          )}
          {loggedIn()}
          {userDetails && (
            <div
              className="container-fluid padding-globle"
              id="movie-container-header"
            >
              <div className="movie-review-wrapper">
                <div className="movie-review-content col-md-8  mx-auto mt-5 ">
                  <div className=" mb-4 section-heading section-heading-band">
                    <h3>Rate & Reviews</h3>
                  </div>
                  <div className="review-user-detail-form ">
                    {chechReview?.length === 0 ? (
                      <>
                        <div className="col-md-12 review-user-detail review-form-header">
                          <div className="review-user-profile">
                            <img
                              src={
                                userDetails ? userDetails?.photo : RatingProfile
                              }
                              alt="user-profile"
                            />
                          </div>
                          <div className="review-user-title">
                            <h5>{userDetails?.userName}</h5>
                          </div>
                        </div>
                        <Formik
                          initialValues={{
                            review: "",
                            rating: "",
                            movieId: "",
                            description: "",
                            user: "",
                          }}
                          onSubmit={(values) => {
                            const payload = {
                              ...values,
                              rating: start,
                              movieId: id,
                              user: user_id,
                            };
                            dispatch(addReview(payload));
                          }}
                        >
                          {({
                            values,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="rating-icons">
                                <Rating
                                  name="rating"
                                  onChange={(value) => {
                                    setStars(value);
                                  }}
                                  onBlur={handleBlur}
                                  defaultValue={values.rating}
                                />
                              </div>
                              <div className="rating-comment-box col-md-12">
                                <div className="rating-comment-title">
                                  <label className="rating-lable-text">
                                    Review Heading
                                  </label>
                                  <input
                                    type="text"
                                    name="review"
                                    defaultValue={values.title}
                                    className="rating-title-area"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                                <div className="rating-comment">
                                  <label className="rating-lable-text">
                                    Write your review
                                  </label>
                                  <textarea
                                    name="description"
                                    type="text"
                                    className="rating-comment-area"
                                    rows="5"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue={values.description}
                                  ></textarea>
                                  <div className="review-submit-sec">
                                    <button type="submit">Submit</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                        <hr />
                      </>
                    ) : (
                      ""
                    )}

                    <div className="user-reviews-sec col-md-12">
                      {reviewLists
                        ? reviewLists?.map((item, index) => {
                            return (
                              <RatingReview
                                key={index}
                                userProfile={
                                  item ? item?.user?.photo : RatingProfile
                                }
                                userName={item?.user?.userName}
                                reviewDate={dateFormat(
                                  item?.createdAt,
                                  "dd/mm/yyyy"
                                )}
                                startRaing={
                                  <Rating
                                    startRaing={item?.rating}
                                    editRating={false}
                                  />
                                }
                                lable={item?.review}
                                description={item?.description}
                              />
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <Register />
            </div>
          )}
          {movieDetails && (
            <Trailer
              trailerLink={movieDetails?.trailerUrl[0] || "NA"}
              onPlayerReady={handlePlayerReady}
              onTrailerClose={onHandleTrailerClose}
            />
          )}
        </>
      )}
      {loggedInId && (
        <StrModal
          modalId="strModal"
          movieId={id}
          transId={movieTransactionId}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    is_loading: state.movie_list.is_loading,
  };
};
export default connect(mapStateToProps, {
  getMovie,
  movieLists,
  getUser,
  addReview,
  updatedRentedList,
})(MyMovie);
